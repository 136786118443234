import React from "react"

import styled, { css } from "styled-components"
import { maxWidth, when } from "utils"
import Icon from "icons"
import minWidth from "utils/minWidth"

export const Container = styled.section`
  padding: ${({ enforceMarginDecrease }) =>
    enforceMarginDecrease ? "1rem 8.5rem 6rem 8.5rem" : "6rem 8.5rem"};
  display: ${({ hideOnPageLoad }) => (hideOnPageLoad ? "none" : "block")};
  background: ${({ theme }) => theme.component?.background || theme.colors.background.page};
  color: ${({ theme }) => theme.component?.text || theme.colors.text.primary};

  ${maxWidth.desktopL`
    padding: 6rem;
  `}

  ${maxWidth.tablet`
    padding: 5rem 4rem;
  `}

  ${maxWidth.mobile`
    padding: ${({ useHorizontalScrollOnMobile, enforceMarginDecrease }) =>
      useHorizontalScrollOnMobile || enforceMarginDecrease ? "3rem 0 3rem 2rem" : "3rem 2rem"} 
     
  `}

  ${when("textComponent")`
    padding: 0rem !important;
  `}

  ${when("isNested")`
    padding: 2rem 0 !important;
   
    ${minWidth.mobile`
      padding: 0 !important;
    `}
  `}
`

export const DesktopWrapper = styled.div`
  ${maxWidth.mobile`
    display: none;
  `}
`

export const MobileWrapper = styled.div`
  display: none;

  ${maxWidth.mobile`
    display: block;
  `}
`

export const PreTitleDesktop = styled.h3`
  margin-bottom: 2rem;
  color: ${({ theme }) => theme.component?.highlight || theme.colors.text.primary};
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.62rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;

  p {
    margin: 0;
  }
`

export const PreTitleMobileWrapper = styled.div`
  display: flex;
`

export const PreTitleLine = styled.span`
  width: 100%;
  height: 0.1rem;
  margin-top: 0.7rem;
  display: inline-block;
  vertical-align: middle;
  background-color: #d0d0d0;
`

export const PreTitleMobile = styled(PreTitleDesktop)`
  margin: 0 1.5rem 3rem;
  color: #222;
`

export const PreHeading = ({ ...props }) => (
  <>
    <DesktopWrapper>
      <PreTitleDesktop {...props} />
    </DesktopWrapper>
    <MobileWrapper>
      <PreTitleMobileWrapper>
        <PreTitleLine />
        <PreTitleMobile {...props} />
        <PreTitleLine />
      </PreTitleMobileWrapper>
    </MobileWrapper>
  </>
)

export const HeadingRow = styled.div`
  ${({ hasTopButton, hasBody }) =>
    (hasTopButton || hasBody) &&
    css`
      margin-bottom: 3rem;
      display: flex;
      align-items: center;

      .section-heading-title {
        margin-bottom: 0;
      }

      ${maxWidth.mobile`
        margin-bottom: 0;
        justify-content: space-between;

        ${when("hasBody")`
          display: block;
        `}
      `}
    `}

  ${({ hasTopButton, hideText }) =>
    hasTopButton &&
    hideText &&
    css`
      justify-content: flex-end;
    `}
`

export const Body = styled.div`
  width: 50%;
  padding-right: 1.5rem;
  font-size: 1.6rem;
  line-height: 2.496rem;

  p {
    margin: 0;
  }

  ${maxWidth.mobile`
    width: 100%;
    margin: 1.5rem 0 3rem;
  `}
`

const ScrollLabel = styled.div`
  margin-top: 1.5rem;
  font-size: 1.6rem;
  line-height: 2.2rem;
`

export const ScrollLabelWithArrow = ({ useLine, label }) => {
  if (!useLine) {
    return (
      <>
        <ScrollLabel>{label || "scroll"}</ScrollLabel>
        <ScrollableMobileIcon viewBox="0 0 348 12" id="arrow-line-mobile" />
      </>
    )
  }
}

export const ScrollContainer = styled.div`
  width: 100%;
`
export const ScrollableMobileIcon = styled(Icon)`
  margin-right: 1rem;
  ${minWidth.mobile`
    margin:0;
  `}
`

export const ScrollableGrid = styled.div`
  display: -webkit-box;
  overflow-x: auto;
`

/**
 * Determines the required size of the last pseudo ::after element in the grid,
 * so that items in the last row are left-aligned.
 */
const getFillerSize = (props, screen = "default") => {
  const numColumns = props.numColumns[screen]
  const itemSize = 100 / numColumns
  const itemsLastRow = props.children.length % numColumns
  const itemsMissing = numColumns - itemsLastRow
  return itemsMissing * itemSize
}

export const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: ${({ isNested }) => !isNested && "wrap"};
  gap: ${({ isNested }) => isNested && "1.7rem"};
  justify-content: ${({ isNested }) => (isNested ? "flex-start" : "space-between")};
  margin-bottom: -${({ rowMargin }) => rowMargin};

  ${when("unevenColumns")`
     align-items: flex-start;
  `}

  ${maxWidth.mobile`
    flex-wrap: wrap;

    ${when("mobileHorizontal")`
      padding-bottom: 2rem;
      flex-wrap: nowrap;
      overflow-x: auto;
      justify-content: flex-start;
      border-bottom: 0.3rem solid #eeeeee;
    `}
  `}

  ::after {
    content: "";
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(${(props) => getFillerSize(props)}% - ${(props) => props.colMargin} / 2);

    ${maxWidth.desktopL`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(${(props) => getFillerSize(props, "desktopL")}% - ${(props) =>
      props.colMargin} / 2);
    `}

    ${maxWidth.hamburgerLarge`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(${(props) => getFillerSize(props, "hamburgerLarge")}% - ${(props) =>
      props.colMargin} / 2);
    `}

    ${maxWidth.desktop`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(${(props) => getFillerSize(props, "desktop")}% - ${(props) =>
      props.colMargin} / 2);
    `}

    ${maxWidth.tablet`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(${(props) => getFillerSize(props, "tablet")}% - ${(props) =>
      props.colMargin} / 2);
    `}

    ${maxWidth.mobile`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(${(props) => getFillerSize(props, "mobile")}% - ${(props) =>
      props.numColumns.mobile < 2 ? "0rem" : props.colMargin} / 2);

      ${when("mobileHorizontal")`
        display: none;
      `}
    `}
  }

  > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(
      ${(props) => 100 / props.numColumns.default}% - ${(props) => props.colMargin} / 2
    );
    margin-bottom: ${(props) => props.rowMargin};
    max-width: 100%;

    ${maxWidth.desktopL`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(${(props) => 100 / props.numColumns.desktopL}% - ${(props) =>
      props.colMargin} / 2);
    `}

    ${maxWidth.hamburgerLarge`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(${(props) => 100 / props.numColumns.hamburgerLarge}% - ${(props) =>
      props.colMargin} / 2);
    `}

    ${maxWidth.desktop`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(${(props) => 100 / props.numColumns.desktop}% - ${(props) =>
      props.colMargin} / 2);
    `}

    ${maxWidth.tablet`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(${(props) => 100 / props.numColumns.tablet}% - ${(props) =>
      props.colMargin} / 2);
    `}

    ${maxWidth.mobile`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(${(props) => 100 / props.numColumns.mobile}% - ${(props) =>
      props.numColumns.mobile < 2 ? "0rem" : props.colMargin} / 2);

      ${when("mobileHorizontal")`
        margin-bottom: 0;
        margin-right: ${(props) => props.colMargin};
      `}
    `}
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${when("bottom")`
    margin-top: 3rem;

    ${maxWidth.mobile`
      button {
        width: 100%;
      }
    `}
  `}
`
