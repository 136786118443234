import React, { useState, useEffect } from "react"
import { parseISO, differenceInSeconds } from "date-fns"
import { Container, Item, Spacer } from "./styles"

const pad = (num) => {
  // Add a zero in front of single digit numbers, except 0
  if (num < 10) return `0${num}`
  // Don't allow numbers to go negative, keep at 0
  if (num < 0) return "00"
  return `${num}`
}

const CountdownTimer = ({ className, targetDate, onTickCallback }) => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  useEffect(() => {
    const dateRemote = new Date(targetDate)
    const dateRemoteMyTimezone = parseISO(dateRemote.toISOString())

    const tick = setInterval(() => {
      let diff = differenceInSeconds(dateRemoteMyTimezone, new Date())
      if (diff > 0) {
        const d = Math.floor(diff / 60 / 60 / 24)
        setDays(d)
        diff -= d * 60 * 60 * 24

        const h = Math.floor(diff / 60 / 60)
        setHours(h)
        diff -= h * 60 * 60

        const m = Math.floor(diff / 60)
        setMinutes(m)
        diff -= m * 60

        setSeconds(diff)
      } else {
        clearInterval(tick)
      }

      if (onTickCallback) {
        onTickCallback(dateRemote)
      }
    }, 1000)

    return () => clearInterval(tick)
  }, [targetDate])

  return (
    <Container className={className}>
      <Item>
        <h6>{pad(days)}</h6>
        <span>Days</span>
      </Item>
      <Spacer />
      <Item>
        <h6>{pad(hours)}</h6>
        <span>Hours</span>
      </Item>
      <Spacer />
      <Item>
        <h6>{pad(minutes)}</h6>
        <span>Minutes</span>
      </Item>
      <Spacer />
      <Item>
        <h6>{pad(seconds)}</h6>
        <span>Seconds</span>
      </Item>
    </Container>
  )
}

export default CountdownTimer
