import React from "react"

import MonolineTextBlock from "components/monoline"
import { ButtonContainer } from "components/grid/styles"
import {
  Container,
  TitleContainer,
  Title,
  Line,
  MindbodygreenTitleWrapper,
  MindbodygreenTitle,
  SocialCounters,
  SocialCounter,
  SocialLink,
  SocialIcon,
  Description,
  LogoContainer,
  Logo,
  HeaderWrapper,
} from "./styles"

const SectionHeading = ({
  className,
  title,
  subtitle,
  useMonoline,
  logo,
  logoAspectRatio,
  mobileNoBorder,
  textComponent,
  useLine,
  useSlimTitle,
  showDescriptionOnMobile,
  buttonElement,
  hasTopButton,
  isNested,
  isScrollable,
}) => (
  <Container className={className} textComponent={textComponent}>
    {title &&
      (title === "Mindbodygreen" && useMonoline ? (
        <MindbodygreenTitleWrapper>
          <MindbodygreenTitle />
          <SocialCounters>
            <SocialCounter>
              <SocialLink
                href="https://www.instagram.com/mindbodygreen"
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon id="social-instagram" width={23} height={23} />
                1.4M
              </SocialLink>
            </SocialCounter>
            <SocialCounter>
              <SocialLink
                href="https://www.tiktok.com/@mindbodygreen"
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon id="social-tiktok" width={23} height={23} />
                97K
              </SocialLink>
            </SocialCounter>
            <SocialCounter>
              <SocialLink
                href="https://www.pinterest.com/mindbodygreen"
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon id="social-pinterest" width={23} height={23} />
                477K
              </SocialLink>
            </SocialCounter>
            <SocialCounter>
              <SocialLink
                href="https://www.facebook.com/mindbodygreen"
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon id="social-facebook" width={23} height={23} />
                2.8M
              </SocialLink>
            </SocialCounter>
            <SocialCounter>
              <SocialLink
                href="https://www.youtube.com/mindbodygreen"
                target="_blank"
                rel="noreferrer"
              >
                <SocialIcon id="social-youtube" width={23} height={23} />
                157K
              </SocialLink>
            </SocialCounter>
            <SocialCounter>
              <SocialLink href="https://twitter.com/mindbodygreen" target="_blank" rel="noreferrer">
                <SocialIcon id="social-twitter" width={23} height={23} />
                278K
              </SocialLink>
            </SocialCounter>
          </SocialCounters>
        </MindbodygreenTitleWrapper>
      ) : (
        <HeaderWrapper>
          <TitleContainer useLine={useLine}>
            <Title
              className="section-heading-title"
              hasSubtitle={!!subtitle}
              hasLogo={!!logo}
              mobileNoBorder={mobileNoBorder}
              useSlimTitle={useSlimTitle}
              hasDescription={showDescriptionOnMobile && Boolean(title && subtitle)}
              hasTopButton={hasTopButton}
              isNested={isNested}
              isScrollable={isScrollable}
            >
              {useMonoline && <MonolineTextBlock html={title} />}
              {!useMonoline && title}
            </Title>
            {hasTopButton && <ButtonContainer>{buttonElement}</ButtonContainer>}
          </TitleContainer>
          {useLine && <Line isNested={isNested} />}
        </HeaderWrapper>
      ))}
    {title && subtitle && (
      <Description
        isScrollable={isScrollable}
        dangerouslySetInnerHTML={{ __html: subtitle }}
        showDescriptionOnMobile={showDescriptionOnMobile}
      />
    )}
    {title && logo && (
      <LogoContainer mobileNoBorder={mobileNoBorder}>
        <Logo
          baseUrl={logo}
          aspectRatio={logoAspectRatio}
          images={[
            {
              transforms: "e_trim/w_150,q_auto,f_auto,fl_lossy",
            },
          ]}
          alt={title}
        />
      </LogoContainer>
    )}
  </Container>
)
export default SectionHeading
