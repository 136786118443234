import React, { Fragment, useCallback, useRef } from "react"
import { useTheme } from "styled-components"

import SectionHeading from "components/section-heading"
import WebinarPart from "./webinar"
import {
  Container,
  Input,
  Select,
  BackgroundImage,
  Relative,
  Success,
  ErrorText,
  NoteInput,
  Label,
  Terms,
} from "./styles"

const renderField = (field, emailPlaceholder, props) => {
  switch (field) {
    case "First Name":
      return (
        <Fragment key={field}>
          <label htmlFor="submit-input-firstName" style={{ display: "none" }}>
            Enter your first name
          </label>
          <Input
            id="submit-input-firstName"
            name="firstName"
            type="text"
            placeholder="First Name"
            {...props}
          />
        </Fragment>
      )
    case "Last Name":
      return (
        <Fragment key={field}>
          <label htmlFor="submit-input-lastName" style={{ display: "none" }}>
            Enter your last name
          </label>
          <Input
            id="submit-input-lastName"
            name="lastName"
            type="text"
            placeholder="Last Name"
            {...props}
          />
        </Fragment>
      )
    case "Full Name":
      return (
        <Fragment key={field}>
          <label htmlFor="submit-input-fullName" style={{ display: "none" }}>
            Enter your full name
          </label>
          <Input
            id="submit-input-fullName"
            name="fullName"
            type="text"
            placeholder="Full Name"
            {...props}
          />
        </Fragment>
      )
    case "Postal Code":
      return (
        <Fragment key={field}>
          <label htmlFor="submit-input-postalCode" style={{ display: "none" }}>
            Enter your postal code
          </label>
          <Input
            id="submit-input-postalCode"
            name="postalCode"
            type="text"
            placeholder="Postal Code"
            {...props}
          />
        </Fragment>
      )

    case "Email":
      return (
        <Fragment key={field}>
          <label htmlFor="submit-input-email" style={{ display: "none" }}>
            Enter your email
          </label>
          <Input
            id="submit-input-email"
            name="email"
            type="email"
            placeholder={emailPlaceholder}
            {...props}
          />
        </Fragment>
      )

    case "Birth Day And Month":
      return (
        <Fragment key={field}>
          <label htmlFor="submit-input-birthDay" style={{ display: "none" }}>
            Enter your birth day
          </label>
          <Input
            id="submit-input-birthDay"
            type="text"
            name="birthDay"
            placeholder="Birth Day (e.g., 03)"
            pattern="0?[1-9]|[12][0-9]|3[01]"
            {...props}
          />
          <label htmlFor="submit-input-birthMonth" style={{ display: "none" }}>
            Enter your birth month
          </label>
          <Input
            id="submit-input-birthMonth"
            type="text"
            name="birthMonth"
            placeholder="Birth Month (e.g., 11)"
            pattern="0?[1-9]|1[012]"
            {...props}
          />
        </Fragment>
      )

    case "Birth Year":
      return (
        <Fragment key={field}>
          <label htmlFor="submit-input-birthYear" style={{ display: "none" }}>
            Enter your birth year
          </label>
          <Input
            id="submit-input-birthYear"
            type="text"
            name="birthYear"
            placeholder="Birth Year (4 digits)"
            pattern="[0-9]{4}"
            {...props}
          />
        </Fragment>
      )

    case "Gender":
      return (
        <Select
          key={field}
          name="gender"
          placeholder="Gender"
          items={[
            { value: "Male", label: "Male" },
            { value: "Female", label: "Female" },
          ]}
          {...props}
        />
      )

    default:
      return null
  }
}

const SignUpForm = ({
  title,
  subtitle,
  useMonoline,
  webinarId,
  ctaText,
  backgroundImage,
  optionalFields,
  requiredFields,
  emailPlaceholder = "E-mail address",
  submitPrimary,
  onSubmit,
  submitting,
  hasError,
  submitted,
  elRef,
  dataId,
  hideOnPageLoad,
  signupType,
  isPromotionalPlacement,
  ...props
}) => {
  const noteRef = useRef(null)
  const submitHandler = useCallback(
    (ev) => {
      ev.preventDefault()
      if (submitting) return
      const fd = new FormData(ev.target)
      const object = {}
      fd.forEach((value, key) => {
        object[key] = value
      })
      if (signupType === "Contact Form") {
        object.note = noteRef.current.value
      }
      onSubmit(object)
    },
    [onSubmit, submitting],
  )

  // render a single field?
  const renderSingle =
    ctaText.length <= 20 && !(optionalFields || []).length && (requiredFields || []).length === 1

  const theme = useTheme()

  const disabled = submitted || submitting

  return (
    <Container hideOnPageLoad={hideOnPageLoad} data-exp-id={dataId} ref={elRef} {...props}>
      {backgroundImage && (
        <BackgroundImage
          baseUrl={backgroundImage}
          images={[
            {
              screenSize: 1024,
              transforms: `c_fill,g_auto,w_1440,h_${
                318 + (optionalFields.length + requiredFields.length) * 50
              },f_auto,q_auto,fl_lossy`,
            },
            {
              screenSize: 768,
              transforms: `c_fill,g_auto,w_1024,h_${
                318 + (optionalFields.length + requiredFields.length) * 50
              },f_auto,q_auto,fl_lossy`,
            },
            {
              transforms: `c_fill,g_auto,w_500,h_${
                318 + (optionalFields.length + requiredFields.length) * 50
              },f_auto,q_auto,fl_lossy`,
            },
          ]}
        />
      )}
      <form onSubmit={submitHandler}>
        <Input isHoneyPot type="text" name="userMessage" />
        {!theme.component.isNested && <SectionHeading title={title} useMonoline={useMonoline} />}
        <div dangerouslySetInnerHTML={{ __html: subtitle }} />
        {webinarId && <WebinarPart webinarId={webinarId} />}
        <Input isHoneyPot type="text" name="webinarQuestion" />
        {renderSingle && !submitted && (
          <Relative primary={submitPrimary} isPromotionalPlacement={isPromotionalPlacement}>
            {renderField(
              requiredFields[0],
              isPromotionalPlacement ? "Email Address" : emailPlaceholder,
              { required: true, disabled },
            )}
            <Input
              type="submit"
              value={submitting ? "Submitting ..." : ctaText}
              onChange={() => null}
              disabled={submitting}
              primary={submitPrimary}
              isPromotionalPlacement={isPromotionalPlacement}
            />
          </Relative>
        )}
        {!renderSingle &&
          requiredFields &&
          requiredFields.map((field) =>
            renderField(field, emailPlaceholder, { required: true, disabled }),
          )}
        {optionalFields &&
          optionalFields.map((field) =>
            renderField(field, emailPlaceholder, { required: false, disabled }),
          )}
        {signupType === "Contact Form" && (
          <Fragment field="Note">
            <NoteInput
              placeholder="How can we help you?"
              ref={noteRef}
              required
              disabled={disabled}
              maxLength="250"
            />
            <Label htmlFor="submit-input-help">(max 250 characters)</Label>
          </Fragment>
        )}
        {!renderSingle && !submitted && (
          <Input
            type="Submit"
            value={submitting ? "Submitting ..." : ctaText}
            onChange={() => null}
            disabled={submitting}
            primary={submitPrimary}
          />
        )}
        {submitted && (
          <Success className="signup-success" dangerouslySetInnerHTML={{ __html: submitted }} />
        )}
        {hasError && (
          <ErrorText id="error">Something went wrong. Please try again later.</ErrorText>
        )}
        <Terms>
          By clicking "{ctaText}" you agree our{" "}
          <a href="/terms-of-use" target="_blank" rel="noreferrer">
            Terms of Use
          </a>{" "}
          and{" "}
          <a href="/privacy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          .
        </Terms>
      </form>
    </Container>
  )
}

export default SignUpForm
