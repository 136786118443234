import React from "react"
import styled, { css } from "styled-components"

import { when, unless, minWidth, maxWidth } from "utils"

import Icon from "icons"
import CloudinaryImage from "components/cloudinary-image"

export const Container = styled.div`
  width: 100%;
  ${when("textComponent")`
    padding: 6rem 8.5rem 0rem 8.5rem;

    ${maxWidth.desktopL`
      padding: 6rem 6rem 0rem 6rem;
    `}

    ${maxWidth.tablet`
      padding: 5rem 4rem 0rem 4rem;
    `}

    ${maxWidth.mobile`
      padding: 3rem 2rem 0 2rem;
    `}
  `}

  ${when("hasBody")`
    width: 50%;

    ${maxWidth.mobile`
      width: 100%;
    `}
  `}
`

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ useLine }) => (useLine ? "center" : "normal")};
`

export const Title = styled.h2`
  margin: ${({ hasTopButton }) => (hasTopButton ? "0" : " 0 auto 3.5rem auto")};
  text-align: center;
  color: ${({ theme }) => theme.component.largeHeading};

  ${when("hasSubtitle")`
    margin: 0 auto;
  `}

  ${when("hasLogo")`
    margin: 0 auto;
  `}

  

  ${maxWidth.mobile`
    text-align: left;

    ${unless("hasLogo")`
      padding-bottom: 0.5rem;
      margin-bottom: 2rem;
      border-bottom: 0.5rem solid rgba(0, 0, 0, 0.07);

      ${({ theme }) =>
        theme.component.isDarkBackground && `border-bottom-color: rgba(255, 255, 255, 0.4);`}
    `}

    ${when("mobileNoBorder")`
      margin-bottom: 3.5rem;
      padding-bottom: 0;
      border-bottom: 0;
      text-align: center;
    `}

    ${when("hasDescription")`
      margin: 0;
    `}
  `}

  ${({ theme }) => {
    if (!theme.component) return ""
    switch (theme.component.sectionHeading) {
      case "Type C":
      case "Type D":
        return css`
          font-family: "Abril", "Helvetica Neue", Helvetica, Arial, sans-serif;
          font-size: ${theme.component.sectionHeading === "Type C" ? "4.4rem" : "3.6rem"};
          font-weight: 400;
          ${maxWidth.mobile`
            ${maxWidth.mobile`
              font-size: ${theme.component.sectionHeading === "Type C" ? "3.6rem" : "3.4rem"};
            `}
          `}
        `
      case "Type E":
        return css`
          font-family: "Maax";
          font-size: 4rem;
          max-width: 20em;
          text-align: center;
          color: #f4f3eb;

          ${maxWidth.mobile`
            font-size: 3rem;
            text-align: center;
          `};
        `
      default:
        return css`
          font-size: 3.5rem;
          ${maxWidth.mobile`
            font-size: 2.8rem;

            ${when("isNested")`
              font-size: 1.8rem;
              margin: 0 auto 0 0;
              `}
          `}
        `
    }
  }}

  ${when("useSlimTitle")`
    margin: 0;
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 4.32rem;
    text-align: left !important;
  `}
    
  ${when("isScrollable")`
      font-size: 4rem;
      line-heigh: 5.4rem;
  `}

  ${when("isNested")`
    margin: 0 auto 0 0;
    font-size: 2.4rem;
    font-family: 'Maax';
    font-weight: 500;
    line-height:3.2rem;
  `}
`

export const Line = styled.span`
  width: calc(100% - 2rem);
  margin: ${({ isNested }) => (isNested ? "1rem 1.5rem 2.5rem 0" : "0.5rem 0 3.5rem 0")};
  height: 0.15rem;
  display: inline-block;
  vertical-align: middle;
  background-color: ${({ theme }) => theme.component.largeHeading || theme.colors.text.primary};

  ${minWidth.tablet`
    width: 100%;
    height: 0.2rem;
  `}
`

export const MindbodygreenTitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
`

const MindbodygreenTitleContainer = styled.div`
  position: relative;
  z-index: 4;
  max-width: 100%;
  max-height: 14.3rem;
  flex: 1;

  ${minWidth.desktop`
    max-width: 55.5%;
  `}
`

export const MindbodygreenTitle = ({ ...props }) => (
  <MindbodygreenTitleContainer {...props}>
    <Icon
      id="home-page-title"
      title="mindbodygreen"
      width="100%"
      height="100%"
      viewBox="0 0 837 143"
    />
  </MindbodygreenTitleContainer>
)

export const SocialCounters = styled.div`
  position: absolute;
  bottom: 35%;
  right: 0;
  z-index: 4;
  display: none;

  ${minWidth.desktop`
    display: flex;
  `}

  ${minWidth.rightRail`
    bottom: 30%;
  `}
`

export const SocialCounter = styled.div`
  width: 5.6rem;
  height: 2.3rem;
  margin-right: 0.45rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.35rem;
  letter-spacing: 0.1rem;

  ${minWidth.rightRail`
    width: 5.9rem;
    margin-right: 1rem;
  `}

  ${minWidth.desktopXL`
    margin-right: 1.5rem;
  `}

  :last-of-type {
    margin-right: 0;
  }
`

export const SocialLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const SocialIcon = styled(Icon)`
  margin-right: 0.5rem;
`

export const Description = styled.div`
  width: 100%;
  padding-right: 2.5rem;
  ${minWidth.mobile`
    width: ${({ isScrollable }) => (isScrollable ? "75%" : "100%")};
    margin-right:0;
  `}
  p {
    margin: 2rem auto 0 auto;
    text-align: ${({ isScrollable }) => !isScrollable && "center"};
    ${({ theme }) => {
      switch (theme.component.sectionHeading) {
        case "Type C":
        case "Type D":
          return css`
            font-size: 1.6rem;
            line-height: 2.9rem;
          `

        case "Type B":
          return css`
            font-size: 1.4rem;
            letter-spacing: 0.133rem;
            line-height: 1.1rem;
            text-transform: uppercase;
          `

        default:
          return css`
            font-size: 1.6rem;
            line-height: 2.1rem;

            ${when("isScrollable")`
            font-size: 1.4rem;
            line-height: 1.5;
              ${minWidth.mobile`
                font-size: 2.7rem;
                line-height: 3.6rem;
                `}

            strong {
            font-family: 'Maax';
            font-weight: 700;
            }  

            em {
              font-family: Abril;
              font-style: italic;
              font-weight: 400;
            }
            `}
          `
      }
    }}
  }

  ${maxWidth.mobile`
    ${({ showDescriptionOnMobile }) => !showDescriptionOnMobile && "display: none;"}
    p {
      text-align: left;
    }
  `}
`

export const LogoContainer = styled.div`
  ${maxWidth.mobile`
    margin-bottom: 2rem;

    ${unless("mobileNoBorder")`
      border-bottom: 0.5rem solid #eeeeee;
    `}
  `}
`

export const Logo = styled(CloudinaryImage)`
  width: 15rem;
  height: calc(15rem * ${({ aspectRatio }) => aspectRatio});
  margin: 1.2rem auto 3.6rem auto;
`
export const HeaderWrapper = styled.div`
  width: 100%;
`
