import { useMemo, useEffect, useCallback } from "react"
import debounce from "lodash.debounce"
import Cookies from "js-cookie"
import { getSegmentDeviceId } from "@mindbodygreen/analytics-tracking-helpers"

import { useUser } from "store/contexts/user"
import { useComponentizedPage } from "store/contexts/componentized-page"
import { getViewport } from "utils"

const DEBOUNCE_AMOUNT = 2000

const isBrowser = typeof window !== "undefined"

const FB_CAPI_EVENTS = [
  "Product Added",
  "Order Completed",
  "Webinar Registered",
  "Meeting Scheduled",
  "Email Registered",
]

const useTracking = () => {
  const viewport = getViewport()
  const {
    user: { loggedIn },
  } = useUser()
  const { pageId = null } = useComponentizedPage()
  let articleId
  let segmentDeviceId

  if (isBrowser) {
    articleId = document.querySelector("meta[name='mbg.article_id']")?.content
    segmentDeviceId = getSegmentDeviceId()
  }

  const baseProps = useMemo(
    () => ({
      viewport,
      logged_in: loggedIn,
      ...(pageId && { pageId }),
      ...(articleId && {
        article_id: articleId,
        page_type: "Article",
      }),
    }),
    [viewport, loggedIn, pageId, articleId],
  )

  const hasAnalytics = () => isBrowser && Boolean(window.analytics)
  const hasElement = (elementRef) => elementRef && elementRef.current

  /**
   * Base tracking function.
   */
  const track = useCallback(
    (event, props) => {
      if (!hasAnalytics()) {
        return
      }

      // Add fbp and fbc to FB CAPI events
      let eventProps = props
      const lowerCaseFBEvents = FB_CAPI_EVENTS.map((eventName) => eventName.toLowerCase())
      if (lowerCaseFBEvents.includes(event.toLowerCase())) {
        eventProps = {
          ...eventProps,
          fbp: Cookies.get("_fbp") || "",
          fbc: Cookies.get("_fbc") || "",
        }
      }

      window?.analytics.track(
        event,
        { ...baseProps, ...eventProps },
        { device: { id: segmentDeviceId } },
      )
    },
    [baseProps, segmentDeviceId],
  )

  /**
   * Tracks when an element is rendered for the first time (tracks only once because of debounce).
   */
  const trackRender = useCallback(
    (elementRef, trackingId, extraProps, onTrack, renderedFlag = "rendered") => {
      if (!hasAnalytics()) {
        return
      }

      const trackDebounced = useCallback(
        debounce((props) => {
          if (!hasElement(elementRef) || elementRef.current[renderedFlag]) {
            return
          }
          elementRef.current[renderedFlag] = true

          if (onTrack) {
            onTrack(props)
          } else {
            track("Element Rendered", props)
          }
        }, DEBOUNCE_AMOUNT),
        [track, onTrack],
      )

      useEffect(() => {
        if (!hasElement(elementRef)) {
          return () => {}
        }

        const props = { ...(trackingId && { element_id: trackingId }), ...extraProps }
        trackDebounced(props)
      }, [trackDebounced, trackingId, extraProps])
    },
    [track],
  )

  /**
   * Tracks an element when 50% of it is visible (tracks only once because of debounce).
   */
  const trackView = useCallback(
    (elementRef, trackingId, extraProps, onTrack, viewedFlag = "viewed") => {
      if (!hasAnalytics()) {
        return
      }

      const trackDebounced = useCallback(
        debounce((props) => {
          if (!hasElement(elementRef) || elementRef.current[viewedFlag]) {
            return
          }
          elementRef.current[viewedFlag] = true

          if (onTrack) {
            onTrack(props)
          } else {
            track("Element Viewed", props)
          }
        }, DEBOUNCE_AMOUNT),
        [track, onTrack],
      )

      useEffect(() => {
        if (!hasElement(elementRef)) {
          return () => {}
        }

        const props = {
          ...(trackingId && { element_id: trackingId }),
          ...extraProps,
        }

        const observer = new IntersectionObserver(
          (entries) => {
            if (entries[0].isIntersecting) {
              trackDebounced(props)
            }
          },
          { threshold: [0.5] },
        )
        const element = elementRef.current
        observer.observe(element)

        return () => observer.unobserve(element)
      }, [trackDebounced, trackingId, extraProps])
    },
    [track],
  )

  /**
   * Tracks when a user clicks an element.
   */
  const trackClick = useCallback(
    (elementRef, trackingId, extraProps, onTrack) => {
      if (!hasAnalytics()) {
        return
      }

      useEffect(() => {
        if (!hasElement(elementRef)) {
          return () => {}
        }

        const props = {
          ...(trackingId && { element_id: trackingId }),
          ...extraProps,
        }

        const handleClick = () => {
          if (onTrack) {
            onTrack(props)
          } else {
            track("Element Clicked", props)
          }
        }

        const element = elementRef.current
        element.addEventListener("click", handleClick)

        return () => element.removeEventListener("click", handleClick)
      }, [track, onTrack, trackingId, extraProps])
    },
    [track],
  )

  return { hasAnalytics, track, trackRender, trackView, trackClick }
}

export default useTracking
