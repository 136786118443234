import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { format, compareAsc } from "date-fns"
import CountdownTimer from "components/hero/countdown"
import { WebinarPart, WebinarSelect } from "./styles"

const WebinarSignUp = ({ webinarId }) => {
  const webinars = useStaticQuery(graphql`
    query webinars {
      allWebinar {
        nodes {
          webinarId
          dates {
            label
            scheduleId
            date
          }
          name
        }
      }
    }
  `)
  const staticWebinar = webinars.allWebinar.nodes.find(
    (wb) => Number(wb.webinarId) === Number(webinarId),
  )
  const [webinar, setWebinar] = useState(staticWebinar)

  useEffect(() => {
    async function getWebinar() {
      const response = await fetch(`/api/everwebinar-webinars/webinars/${webinarId}`)
      if (!response.ok) {
        console.log(response.statusText)
        return
      }
      const data = await response.json()
      setWebinar(data.webinar)
    }
    getWebinar()
  }, [webinarId])

  if (!webinar) return null

  const futureDates = webinar.dates.filter((d) => compareAsc(new Date(d.date), new Date()) > 0)
  if (!futureDates.length) return null

  const dates = futureDates.reduce((agg, next) => {
    const date = format(new Date(next.date), "EEEE, dd LLL yyyy")
    const time = format(new Date(next.date), "hh:mm aa")

    return {
      ...agg,
      [date]: [
        ...(agg[date] || []),
        {
          time,
          scheduleId: next.scheduleId,
        },
      ],
    }
  }, {})

  const [date, setDate] = useState(Object.keys(dates)[0])
  return (
    <WebinarPart>
      <div>
        <p>Next event starts in ...</p>
        <CountdownTimer targetDate={futureDates[0].date} />
      </div>
      <div>
        <WebinarSelect
          name="eventDate"
          placeholder="Select date"
          items={Object.keys(dates).map((d) => ({ value: d, label: d }))}
          onChange={setDate}
          required
        />
        <WebinarSelect
          name="scheduleId"
          placeholder="Select session"
          items={
            date
              ? dates[date].map((schedule) => ({
                  value: schedule.scheduleId,
                  label: schedule.time,
                }))
              : []
          }
          required
        />
      </div>
    </WebinarPart>
  )
}

export default WebinarSignUp
