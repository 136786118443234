const applyImageCloudinaryTransforms = (url, transforms) => {
  if (!url) {
    console.warn("No url provided for these transforms", transforms)
    return null
  }

  let newUrl = url

  // url is missing a "//res.mindbodygreen.com" prefix
  if (url.startsWith("usr/")) {
    newUrl = `//res.mindbodygreen.com/img/${url}`
  }

  newUrl = newUrl
    .replace(
      /https:\/\/cdn\.accentuate\.io\//,
      "https://mindbodygreen-res.cloudinary.com/image/upload/act/",
    )
    .replace(
      /^((.+)?res\.mindbodygreen\.com)?\/?img/,
      "https://mindbodygreen-res.cloudinary.com/image/upload",
    )
    .replace("res.cloudinary.com/mindbodygreen", "mindbodygreen-res.cloudinary.com")

  if (newUrl.includes("shopify")) {
    return `https://res.cloudinary.com/mindbodygreen/image/fetch/${transforms}/${url}`
  }

  if (newUrl.includes("/image/upload/")) {
    const [left, right] = newUrl.split("/image/upload/")
    return `${left}/image/upload/${transforms}/${right}`
  }

  if (newUrl.includes("/images/")) {
    const [left, right] = newUrl.split("/images/")
    return `${left}/images/${transforms}/${right}`
  }

  return newUrl
}

const applyVideoCloudinaryTransforms = (url, transforms) => {
  if (!url) {
    console.warn("No url provided for these transforms", transforms)
    return null
  }

  const newUrl = url
    .replace(
      /^((.+)?res\.mindbodygreen\.com)?\/?video/,
      "https://mindbodygreen-res.cloudinary.com/video/upload",
    )
    .replace("res.cloudinary.com/mindbodygreen", "mindbodygreen-res.cloudinary.com")

  if (newUrl.includes("/video/upload/")) {
    const [left, right] = newUrl.split("/video/upload/")
    return `${left}/video/upload/${transforms}/${right}`
  }

  if (newUrl.includes("/video/")) {
    const [left, right] = newUrl.split("/video/")
    return `${left}/video/${transforms}/${right}`
  }

  return newUrl
}

module.exports = {
  applyImageCloudinaryTransforms,
  applyVideoCloudinaryTransforms,
}
