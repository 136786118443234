import React from "react"

import { TextBlock } from "./styles"

const MonolineText = ({ html }) => {
  const htmlNew = html
    .replace(/(mbg)/gi, '<span class="mbg">Mbg</span>') // 'gi' makes it case-insensitive
    .replace(/<em>([^<]*)<\/em>/gi, (...args) => {
      let [, p1] = args

      // capitalize special monoline characters at beginning of word
      if (["s", "h", "l", "c", "n", "m", "r"].includes(p1.charAt(0).toLowerCase())) {
        p1 = p1.charAt(0).toUpperCase() + p1.slice(1)
      }

      // Use 'toLowerCase()' on 'p1' to make the startsWith checks case-insensitive
      if (p1.toLowerCase().startsWith("shop") || p1.toLowerCase().startsWith("supplement")) {
        return `<span class="supplements">${p1}</span>`
      }

      if (p1.toLowerCase().startsWith("classes")) {
        return `<span class="classes">${p1}</span>`
      }

      if (p1.toLowerCase().startsWith("podcast")) {
        return `<span class="podcasts">${p1}</span>`
      }

      if (p1.toLowerCase().startsWith("functional nutrition coaching")) {
        return `<span class="fnc">${p1}</span>`
      }

      if (p1.toLowerCase().startsWith("collective")) {
        return `<span class="collective">${p1}</span>`
      }

      return `<span class="category">${p1}</span>`
    })

  return <TextBlock dangerouslySetInnerHTML={{ __html: htmlNew }} />
}

export default MonolineText
