import styled from "styled-components"

import { when } from "utils"

export const Dropdown = styled.ul`
  position: absolute;
  max-height: 0;
  overflow-y: auto;
  z-index: 10;

  top: 0;
  left: 0;
  right: 0;

  list-style: none;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 0 2px 7px rgba(145, 145, 145, 0.35);
`

export const Container = styled.div`
  position: relative;

  :focus-within ${Dropdown} {
    max-height: 40rem;
  }

  ${when("disabled")`
    pointer-events: none;
    cursor: not-allowed;
  `}
`

export const Content = styled.div`
  width: 100%;
  padding: 1.35rem 2.4rem;
  border: 0.2rem solid rgb(201, 201, 201);
  border-radius: 3rem;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.003rem;
  color: ${({ theme }) => theme.component.text};
  cursor: pointer;

  ${when("placeholder")`
    color: rgba(150, 150, 150);
  `}

  ${when("disabled")`
    background-color: #f2f2f2 !important;
  `}
`

export const Arrow = styled.div`
  width: 0;
  height: 0;
  position: absolute;
  right: 7%;
  top: calc(50% - 0.3rem);
  border-left: 0.5rem solid transparent;
  border-right: 0.5rem solid transparent;
  border-top: 0.8rem solid #aaaaaa;
`

export const Item = styled.li`
  padding: 1.6rem;
  color: #220000;
  text-align: left;
  cursor: pointer;

  &[aria-selected="true"],
  :hover {
    background: #f3f3f3;
  }
`
