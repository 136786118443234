import styled from "styled-components"

import { when } from "utils"

export const Image = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: ${({ contain }) => (contain ? "contain" : "cover")};
  }

  ${when("lazy")`
    /* fill the image area with 1x1 placeholder */
    img[class~="lazy"] {
      object-fit: fill;
    }
  `}
`

export const VideoGif = styled.video``
