import styled from "styled-components"
import { maxWidth } from "utils"

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const Item = styled.div`
  flex: 0 0 24%;
  text-transform: uppercase;

  h6 {
    font-size: 2.4rem;
    letter-spacing: 0.3rem;

    ${maxWidth.mobile`
      font-size: 2rem;
    `}
  }

  span {
    font-size: 1.2rem;
    letter-spacing: 0.13rem;
    line-height: 2.6rem;
    font-weight: 500;

    ${maxWidth.mobile`
      font-size: 1rem;
    `}
  }
`

export const Spacer = styled.div`
  flex: 0 0 1%;
  font-weight: 500;
  font-size: 2.4rem;

  ::after {
    content: ":";
  }

  ${maxWidth.mobile`
    font-size: 2rem;
  `}
`
