import { useComponentizedPage } from "store/contexts/componentized-page"
import hexify from "./hexify"

const componentThemeExtension = (
  backgroundColor,
  isNested = false,
  onTop = false,
  componentOverrideTheme = null,
) => {
  const { template = "Standard", pageTheme = {} } = useComponentizedPage()
  const {
    headingFontColor,
    sectionHeadingStyle,
    lightPrimaryBackgroundColor,
    lightSecondaryBackgroundColor,
    darkPrimaryBackgroundColor,
    darkSecondaryBackgroundColor,
    primaryCtaColor,
    darkBackgroundCtaColor,
    secondaryCtaButtonColor,
    textHighlightColor,
    lightPrimaryFontColor,
    lightSecondaryFontColor,
    darkPrimaryFontColor,
    darkSecondaryFontColor,
    gradientStyle,
  } = pageTheme

  const getSectionHeading = (style) => {
    switch (style) {
      case "Standard":
        return "Type A"
      case "Upper-Case Subheading":
        return "Type B"
      case "Large Serif":
        return "Type C"
      case "Small Serif":
        return "Type D"
      default:
        return null
    }
  }

  const colorsOverride = {
    "Light Background Primary": hexify(lightPrimaryBackgroundColor),
    "Light Background Secondary": hexify(lightSecondaryBackgroundColor),
    "Dark Background Primary": hexify(darkPrimaryBackgroundColor),
    "Dark Background Secondary": hexify(darkSecondaryBackgroundColor),
    "primaryCtaColor": hexify(primaryCtaColor),
    "darkBackgroundCtaColor": hexify(darkBackgroundCtaColor),
    "secondaryCtaButtonColor": hexify(secondaryCtaButtonColor),
    "textHighlightColor": hexify(textHighlightColor),
    "headingFontColor": hexify(headingFontColor),
  }

  const textOverride = {
    "Light Background Primary": hexify(lightPrimaryFontColor),
    "Light Background Secondary": hexify(lightSecondaryFontColor),
    "Dark Background Primary": hexify(darkPrimaryFontColor),
    "Dark Background Secondary": hexify(darkSecondaryFontColor),
  }

  const getGradientBackground = (gradientStyle, theme) => {
    const lightBgc = hexify(theme.lightPrimaryBackgroundColor) || "#FEFEFE"
    const darkBgc = hexify(theme.darkPrimaryBackgroundColor) || "#D0ECF1"

    switch (gradientStyle) {
      case "Light Background Color to Dark Background Color":
        return `linear-gradient(to bottom, ${lightBgc}, ${darkBgc})`
      case "Dark Background Color to Light Background Color":
        return `linear-gradient(to bottom, ${darkBgc}, ${lightBgc})`
      case "White to Dark Background Color":
        return `linear-gradient(to bottom, #FFFFFF, ${darkBgc})`
      case "Black to Light Background Color":
        return `linear-gradient(to bottom, #000000, ${lightBgc})`
      default:
        return `linear-gradient(to bottom, ${lightBgc}, ${darkBgc})`
    }
  }

  const generateTheme = (oldTheme, isDarkBackground, template, actualTheme = null) => {
    const mainTheme = {
      ...oldTheme,
      component: {
        isNested,
        onTop,
        isDarkBackground,
        background: actualTheme?.gradientStyle
          ? getGradientBackground(actualTheme.gradientStyle, actualTheme)
          : gradientStyle
          ? getGradientBackground(gradientStyle, pageTheme)
          : actualTheme &&
            (actualTheme.lightPrimaryBackgroundColor || actualTheme.darkPrimaryBackgroundColor)
          ? isDarkBackground
            ? hexify(actualTheme.darkPrimaryBackgroundColor)
            : hexify(actualTheme.lightPrimaryBackgroundColor)
          : colorsOverride[backgroundColor] ||
            oldTheme.contentfulTemplates[template][backgroundColor] ||
            "initial",
        text: isDarkBackground
          ? actualTheme && actualTheme.darkPrimaryFontColor
            ? hexify(actualTheme.darkPrimaryFontColor)
            : textOverride[backgroundColor] || oldTheme.colors.text.alternate
          : actualTheme && actualTheme.lightPrimaryFontColor
          ? hexify(actualTheme.lightPrimaryFontColor)
          : textOverride[backgroundColor] || oldTheme.colors.text.primary,
        textSecondary: isDarkBackground
          ? actualTheme && actualTheme.darkSecondaryFontColor
            ? hexify(actualTheme.darkSecondaryFontColor)
            : textOverride[backgroundColor] || oldTheme.colors.text.alternate
          : actualTheme && actualTheme.lightSecondaryFontColor
          ? hexify(actualTheme.lightSecondaryFontColor)
          : textOverride[backgroundColor] || oldTheme.colors.text.primary,
        category: oldTheme.contentfulTemplates[template]["Category Color"],
        cta:
          actualTheme && actualTheme.primaryCtaColor
            ? hexify(actualTheme.primaryCtaColor)
            : colorsOverride.primaryCtaColor ||
              oldTheme.contentfulTemplates[template]["Primary CTA"],
        ctaInverted:
          actualTheme && actualTheme.darkBackgroundCtaColor
            ? hexify(actualTheme.darkBackgroundCtaColor)
            : colorsOverride.darkBackgroundCtaColor ||
              oldTheme.contentfulTemplates[template]["Primary CTA Inverted"] ||
              oldTheme.contentfulTemplates[template]["Primary CTA"],
        ctaSecondary:
          actualTheme && actualTheme.secondaryCtaButtonColor
            ? hexify(actualTheme.secondaryCtaButtonColor)
            : colorsOverride.secondaryCtaButtonColor ||
              oldTheme.contentfulTemplates[template]["Category Color"],
        sectionHeading: actualTheme
          ? getSectionHeading(actualTheme.sectionHeadingStyle) ||
            getSectionHeading(sectionHeadingStyle)
          : oldTheme.contentfulTemplates[template].sectionHeading,
        highlight:
          actualTheme && actualTheme.textHighlightColor
            ? hexify(actualTheme.textHighlightColor)
            : colorsOverride.textHighlightColor ||
              oldTheme.contentfulTemplates[template]["Highlight Color"],
        largeHeading:
          actualTheme && actualTheme.headingFontColor
            ? hexify(actualTheme.headingFontColor)
            : colorsOverride.headingFontColor ||
              oldTheme.contentfulTemplates[template]["Large Heading Color"],
      },
    }
    return mainTheme
  }

  return (oldTheme) => {
    const isDarkBackground = backgroundColor
      ? /(Dark|Category|Black)/.test(backgroundColor)
      : oldTheme.component
      ? oldTheme.component.isDarkBackground
      : false

    return componentOverrideTheme
      ? generateTheme(oldTheme, isDarkBackground, template, componentOverrideTheme)
      : generateTheme(oldTheme, isDarkBackground, template)
  }
}

export default componentThemeExtension
