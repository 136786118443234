import styled from "styled-components"

export const TextBlock = styled.span`
  font-family: Monoline, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: none;
  letter-spacing: 0;

  span.mbg {
    color: ${({ theme }) => theme.component.text};
    ${({ theme }) => theme.component.isDarkBackground && `opacity: 0.8;`}
  }

  span.category {
    color: ${({ theme }) => theme.component.category};
  }

  span.classes {
    color: ${({ theme }) => theme.colors.classesTeal};
  }

  span.supplements {
    color: #d24715;
  }

  span.podcasts {
    color: #378358;
  }

  span.fnc {
    color: #4a0527;
  }

  span.collective {
    font-family: Maax, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 500;
    text-transform: lowercase;
    color: #b6471f;
  }
`
