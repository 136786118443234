import React, { useState, useRef, useEffect } from "react"
import { Container, Content, Dropdown, Arrow, Item } from "./styles"

const SelectInput = ({
  left,
  items,
  initialValue,
  initialLabel,
  placeholder,
  required,
  name,
  onChange,
  disabled,
  ...props
}) => {
  const [isOpened, setOpened] = useState(false)
  const [value, setValue] = useState("")
  const [label, setLabel] = useState("")
  const containerRef = useRef(null)

  const nItems = items ? items.length : 0

  // reset select value when number of items changes
  useEffect(() => {
    if (nItems > 0) {
      setValue(initialValue || items[0].value)
      setLabel(
        initialValue ? items.find((item) => item.value === initialValue).label : items[0].label,
      )
    }
  }, [nItems, initialValue])

  if (nItems === 0) {
    return null
  }

  return (
    <Container
      {...props}
      tabIndex={disabled ? -1 : 0}
      onFocus={() => setOpened(true)}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setOpened(false)
        }
      }}
      ref={containerRef}
      disabled={disabled}
    >
      {left}
      <input
        type="hidden"
        name={name}
        value={value}
        onChange={() => null}
        role="combobox"
        aria-controls={`dropdown-${name}`}
        aria-expanded={isOpened}
        aria-autocomplete="list"
        aria-haspopup="listbox"
      />
      <Content {...(!label && placeholder && { placeholder })} disabled={disabled}>
        {!label && placeholder ? placeholder : label}
      </Content>
      <Arrow aria-controls={`dropdown-${name}`} aria-expanded={isOpened} aria-haspopup="listbox" />
      <Dropdown
        id={`dropdown-${name}`}
        role="listbox"
        aria-label={`Open ${name} select`}
        onClick={(event) => {
          setValue(event.target.dataset.value)
          setLabel(event.target.innerHTML)
          Array.from(event.target.parentNode.children).forEach((option) => {
            option.setAttribute(
              "aria-selected",
              option.dataset.value === event.target.dataset.value,
            )
          })
          if (onChange) {
            onChange(event.target.dataset.value)
          }
          event.target.blur()
          containerRef.current.blur()
        }}
        onKeyUp={(event) => {
          switch (event.key) {
            case "Enter":
              event.preventDefault()
              event.target.click()
              break
            case "Escape":
              event.preventDefault()
              event.target.blur()
              containerRef.current.blur()
              break
            default:
              break
          }
        }}
      >
        {items.map((item, i) => (
          <Item
            onFocus={() => setOpened(true)}
            onBlur={(event) => {
              if (!event.currentTarget.parentNode.contains(event.relatedTarget)) {
                setOpened(false)
              }
            }}
            key={item.value}
            tabIndex={disabled ? -1 : 0}
            role="option"
            aria-selected={initialValue ? item.value === initialValue : i === 0}
            data-value={item.value}
          >
            {item.label}
          </Item>
        ))}
      </Dropdown>
    </Container>
  )
}

export default SelectInput
