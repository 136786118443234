import React, { useRef } from "react"

import { applyImageCloudinaryTransforms } from "utils/applyCloudinaryTransforms"

import { Image, VideoGif } from "./styles"

export const isGifImage = (url) => url.split(".").pop() === "gif"
export const gifToMp4Extension = (path) => path.substr(0, path.lastIndexOf(".")).concat(".mp4")
export const removeNonVideoTransforms = (transforms) =>
  transforms
    .split(",")
    .filter((transform) => !["f_auto", "fl_lossy"].includes(transform))
    .join()

export const Gif = ({ children }) => {
  const refVideo = useRef(null)

  const playPause = () => {
    const video = refVideo.current
    if (video.paused) {
      video.play()
    } else {
      video.pause()
    }
  }

  const handleKeyDown = (event) => {
    if (event.code === "Enter" || event.code === "Space") {
      event.preventDefault()
      playPause()
    }
  }

  return (
    <VideoGif
      ref={refVideo}
      autoPlay
      loop
      muted
      playsInline
      tabIndex={0}
      onClick={playPause}
      onKeyDown={handleKeyDown}
    >
      {children}
      Your browser does not support the video tag.
    </VideoGif>
  )
}

/**
 * Lazy-loaded, art-directed image from Cloudinary. If lazy-loading is enabled,
 * a 1x1 pixel is sampled from the remote image and used as a placeholder while
 * the image is loading. Make sure to set "padding-bottom: X%" on the container
 * element - this sets the aspect ratio of container. By default the image will
 * not be displayed ("height: 0").
 *
 * The images property is an array of images, one for each device. Each element
 * of the array has the following properties:
 *
 * {
 *   screenSize: 1024, // minimum screen width in pixels, desktop and up in this case
 *   url: "https://url-to-image", // URL for desktop images
 *   transforms: "c_fill,w_300,h_400" // cloudinary transforms for desktop
 * },
 *
 * If url is missing, the baseUrl property is used. If screenSize is missing,
 * the image is taken to be the default (usually mobile) image.
 */
const CloudinaryImage = ({
  elRef,
  lazy,
  alt,
  baseUrl,
  images,
  noRetina,
  fetchpriority,
  ...props
}) => {
  if (!Array.isArray(images) || !images.length) {
    throw new Error("Must pass in an array of image configurations")
  }
  const role = !alt ? "presentation" : ""

  const defaultImage = images.find((s) => !s.screenSize)
  if (!defaultImage) {
    throw new Error("CloudinaryImage requires a default image (one without screenSize)")
  }

  return (
    <Image ref={elRef} lazy={lazy} {...props}>
      {baseUrl && isGifImage(baseUrl) ? (
        <Gif>
          {images.map(({ url, screenSize, transforms }) => {
            const videoUrl = gifToMp4Extension(
              applyImageCloudinaryTransforms(url || baseUrl, removeNonVideoTransforms(transforms)),
            )

            return (
              <source
                key={screenSize}
                media={`(min-width: ${screenSize}px)`}
                type="video/mp4"
                src={videoUrl}
              />
            )
          })}
        </Gif>
      ) : (
        <picture>
          {images.map(({ url, screenSize, transforms }) => {
            const widthregex = /w_/
            const heightregex = /h_/
            const width = transforms
              .split(",")
              ?.find((e) => e.match(widthregex))
              ?.slice(2)
            const height = transforms
              .split(",")
              ?.find((e) => e.match(heightregex))
              ?.slice(2)

            const url1x = applyImageCloudinaryTransforms(url || baseUrl, transforms)
            const url2x = applyImageCloudinaryTransforms(
              url || baseUrl,
              transforms.concat("/dpr_2.0"),
            )

            const srcSet = `${url1x}${noRetina ? "" : `, ${url2x} 2x`}`
            const srcProps = { srcSet }

            if (!screenSize) {
              if (lazy) {
                return (
                  <img
                    key="default"
                    loading="lazy"
                    fetchpriority="low"
                    role={role}
                    alt=""
                    {...(alt && { alt, title: alt })}
                    src={url1x}
                    {...(width && { width })}
                    {...(height && { height })}
                    {...srcProps}
                  />
                )
              }

              return (
                <img
                  key="default"
                  role={role}
                  alt=""
                  {...(fetchpriority && { fetchpriority })}
                  {...(alt && { alt, title: alt })}
                  src={url1x}
                  {...(width && { width })}
                  {...(height && { height })}
                  {...srcProps}
                />
              )
            }

            return (
              <source
                key={screenSize}
                media={`(min-width: ${screenSize}px)`}
                {...(width && { width })}
                {...(height && { height })}
                {...srcProps}
              />
            )
          })}
        </picture>
      )}
    </Image>
  )
}

export default CloudinaryImage
