import styled from "styled-components"

import { maxWidth, minWidth, when } from "utils"

import CloudinaryImage from "components/cloudinary-image"
import SelectInput from "components/select-input"

export const Container = styled.section`
  display: ${({ hideOnPageLoad }) => (hideOnPageLoad ? "none" : "block")};
  position: relative;
  padding: 6rem 1.5rem 5rem;

  ${({ theme }) => theme.component.isNested && "padding: 1rem 0;"}
  ${({ theme }) => !theme.component.isNested && `background: ${theme.component.background};`}

  color: ${({ theme }) => theme.component.text};

  form {
    max-width: 70rem;
    margin: 0 auto;
    text-align: center;
    position: relative;

    p {
      font-size: 1.6rem;
      line-height: 2.1rem;
    }
  }
`

export const BackgroundImage = styled(CloudinaryImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const Input = styled.input`
  width: 100%;
  margin: 2.5rem auto;
  border-radius: 10rem;
  display: ${({ isHoneyPot }) => (isHoneyPot ? "none" : "block")};

  &[type="email"],
  &[type="text"] {
    background: #fff;
    border: 0.2rem solid #aaaaaa;
    padding: 2.3rem 2.4rem;

    ${({ theme }) => theme.component.isDarkBackground && "border-color: #fff;"}

    color: #220000;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: 0.003rem;
  }

  &[type="submit"] {
    background-color: ${({ primary, theme }) =>
      primary ? theme.component.cta : theme.component.ctaSecondary};
    color: ${({ theme }) => theme.colors.text.alternate};

    ${({ theme }) =>
      theme.component.isDarkBackground &&
      `
      background-color: ${theme.component.ctaInverted};
      color: ${theme.colors.text.primary};
    `}

    appearance: none; /* iOS */

    cursor: pointer;
    border: 0;
    padding: 1.1rem 2rem;
    font-size: 1.4rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.2rem;

    ${maxWidth.mobile`
      width: 100%;
    `}
  }

  &[disabled] {
    cursor: not-allowed;
    background: #f2f2f2;
  }
`

export const Select = styled(SelectInput)`
  width: 100%;
  margin: 2.5rem auto;

  input[type="text"] {
    border-radius: 10rem;

    background: #fff;
    border: 0.2rem solid #aaaaaa;
    padding: 2.3rem 2.4rem;

    ${({ theme }) => theme.component.isDarkBackground && "border: 0;"}

    color: #220000;
    font-size: 1.8rem;
    font-weight: 400;
    letter-spacing: 0.003rem;
  }
`

export const Relative = styled.div`
  position: relative;
  width: ${({ theme }) => (theme.component.isNested ? "100%" : "80%")};
  margin: 0 auto;

  ${maxWidth.mobile`
    width: 100%;
  `}

  ${when("isPromotionalPlacement")`
    width: 100%;
  `}

  > input:not([type="submit"]) {
    width: 100%;

    ${minWidth.desktop`
      ${when("isPromotionalPlacement")`
        width: 72%
        ${({ hasMedia }) => hasMedia && "width: 65%;"}
      `}
    `}
  }

  > input[type="submit"] {
    position: absolute;
    top: 0;
    right: -1px;
    width: auto;
    height: 5rem;
    margin: 0;
    margin-left: 1.2rem;
    background-color: ${({ primary, theme }) =>
      primary ? theme.component.cta : theme.component.ctaSecondary};
    color: ${({ theme }) => theme.colors.text.alternate};

    ${({ theme }) =>
      theme.component.isDarkBackground &&
      `
      background-color: ${theme.component.ctaInverted};
      color: ${theme.colors.text.primary};
    `}

    ${maxWidth.mobile`
      width: 100%;
      position: static;
      margin: 0;
      margin-bottom: 2.5rem;
    `}
  }
`

export const WebinarPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;

  ${maxWidth.mobile`
    flex-direction: column;
  `}

  > * {
    flex: 0 0 48%;

    ${maxWidth.mobile`
      flex: 0 0 100%;
      width: 100%;
    `}
  }
`

export const WebinarSelect = styled(Select)`
  width: 100%;
`

export const Success = styled.div`
  height: 4.5rem;
  margin: 2.5rem;

  ${maxWidth.mobile`
    margin: 2.5rem 0;
    height: 5.5rem;
  `}

  p {
    margin: 0;
  }

  p:nth-child(2) {
    margin-top: 1rem;
  }

  a {
    text-decoration: underline;
  }
`

export const ErrorText = styled.p`
  color: ${({ theme }) => theme.component.text};
`

export const NoteInput = styled.textarea`
  font-family: "Maax";
  background: #fff;
  border: 0.2rem solid #aaaaaa;
  padding: 2.3rem 2.4rem;
  color: #220000;
  font-size: 1.8rem;
  font-weight: 400;
  letter-spacing: 0.003rem;
  width: 100%;
  border-radius: 2rem;
  resize: none;

  ${maxWidth.mobile`
    width: 100%;
  `}

  &[disabled] {
    cursor: not-allowed;
    background: #f2f2f2;
  }
`

export const Label = styled.p`
  padding-top: 0.5rem;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  font-weight: 500;
`

export const InputContainter = styled.div`
  display: flex;
`

export const Terms = styled.div`
  font-size: 1.2rem;
  line-height: 2.16rem;

  a {
    text-decoration: underline;
  }
`
